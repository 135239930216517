<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      Argus Monitoring Dashboard
    </h2>
    <Card>
      <template #content>
        <DataTable
          :value="snapshots"
          class="p-datatable-sm"
          :paginator="true"
          :rows="10"
          :filters="filters"
          :rowsPerPageOptions="[10, 20, 50]"
        >
          <Column
            field="groupName"
            header="Partner Name"
            :sortable="true"
          ></Column>
          <Column field="dailyRequestsCap" header="Daily Cap" :sortable="true">
            <template #body="slotProps">
              <div class="text-gray-500">
                {{ formatNumber(slotProps.data.dailyRequestsCap) }}
              </div>
            </template>
          </Column>
          <Column
            field="currentMetrics.rollingWindow.requests"
            header="Window Requests"
            :sortable="true"
          >
            <template #body="slotProps">
              {{
                formatNumber(
                  slotProps.data.currentMetrics.rollingWindow.requests
                )
              }}
            </template>
          </Column>
          <Column
            field="currentMetrics.daily.requests"
            header="Current Requests"
            :sortable="true"
          >
            <template #body="slotProps">
              {{ formatNumber(slotProps.data.currentMetrics.daily.requests) }}
            </template>
          </Column>
          <Column
            field="capUtilization"
            header="Cap Utilization"
            :sortable="true"
          >
            <template #body="slotProps">
              <div :class="getUtilizationClass(slotProps.data.capUtilization)">
                {{ formatPercent(slotProps.data.capUtilization) }}
              </div>
            </template>
          </Column>
          <Column
            field="fillrateBenchmark"
            header="Fill Rate Benchmark"
            :sortable="true"
          >
            <template #body="slotProps">
              <div class="text-gray-500">
                {{ formatPercent(slotProps.data.fillrateBenchmark) }}
              </div>
            </template>
          </Column>
          <Column
            field="currentMetrics.rollingWindow.fillRate"
            header="Current Fill Rate"
            :sortable="true"
          >
            <template #body="slotProps">
              {{
                formatPercent(
                  slotProps.data.currentMetrics.rollingWindow.fillRate
                )
              }}
            </template>
          </Column>
          <Column
            field="fillRateDiff"
            header="Fill Rate Difference"
            :sortable="true"
          >
            <template #body="slotProps">
              <div :class="getFillRateClass(slotProps.data.fillRateDiff)">
                {{ formatDiff(slotProps.data.fillRateDiff) }}
              </div>
            </template>
          </Column>
          <Column field="bbFactor" header="BB Factor" :sortable="true">
            <template #body="slotProps">
              <div :class="getBBFactorClass(slotProps.data.bbFactor)">
                {{ slotProps.data.bbFactor.toFixed(2) }}
              </div>
            </template>
          </Column>
          <Column field="bbFactorV2" header="v2" :sortable="true">
            <template #body="slotProps">
              <div :class="getBBFactorClass(slotProps.data.bbFactorV2)">
                {{ slotProps.data.bbFactorV2.toFixed(2) }}
              </div>
            </template>
          </Column>
          <Column field="bbp" header="Config BBP" :sortable="true">
            <template #body="slotProps">
              <div class="text-gray-500">
                {{ slotProps.data.bbp.toFixed(2) }}
              </div>
            </template>
          </Column>
          <Column field="finalbbp" header="Final BBP" :sortable="true">
            <template #body="slotProps">
              <div class="text-gray-500">
                {{ slotProps.data.finalbbp.toFixed(2) }}
              </div>
            </template>
          </Column>
          <Column field="activated" header="Activated" :sortable="true">
            <template #body="slotProps">
              <div class="text-gray-500">
                {{ slotProps.data.activated }}
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
    </Card>
    <div class="mt-4">
      <div class="flex flex-wrap gap-2 mb-4">
        <label
          v-for="metric in metrics"
          :key="metric.value"
          class="flex items-center"
        >
          <input
            type="checkbox"
            v-model="selectedMetrics"
            :value="metric.value"
            class="mr-2"
          />
          {{ metric.label }}
        </label>
      </div>

      <select v-model="selectedGroupId" class="p-2 border rounded mb-4">
        <option
          v-for="snapshot in snapshots"
          :key="snapshot.groupId"
          :value="snapshot.groupId"
        >
          {{ snapshot.groupName }}
        </option>
      </select>

      <line-chart
        v-if="chartData.length"
        class="h-96"
        :data="chartData"
        :options="{
          responsive: true,
          maintainAspectRatio: false,
          legend: { display: true, position: 'right' },
        }"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onUnmounted } from "vue";
import { useStore } from "vuex";
import { FilterMatchMode } from "primevue/api";
import Card from "primevue/card";
import axios from "axios";
import { API_BASE_URL } from "../consts";

const store = useStore();
const snapshots = ref([]);
const updateInterval = ref(null);
const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});

const selectedGroupId = ref(null);
const selectedMetrics = ref(["current_requests", "current_impressions"]);
const chartData = ref([]);

const metrics = [
  { label: "Current Requests", value: "current_requests" },
  { label: "Current Impressions", value: "current_impressions" },
  { label: "Daily Requests Cap", value: "daily_requests_cap" },
  { label: "Cap Utilization %", value: "cap_utilization_percent" },
  { label: "Fill Rate Benchmark", value: "fillrate_benchmark" },
  { label: "Current Fill Rate", value: "current_fillrate" },
  { label: "Fill Rate Difference", value: "fillrate_difference" },
  { label: "BB Factor", value: "bb_factor" },
];

const formatNumber = (num) => new Intl.NumberFormat().format(num);
const formatPercent = (num) => `${num.toFixed(1)}%`;
const formatDiff = (diff) => `${diff > 0 ? "+" : ""}${diff.toFixed(1)}%`;

const getUtilizationClass = (utilization) => ({
  "text-green-500": utilization < 80,
  "text-yellow-500": utilization >= 80 && utilization < 90,
  "text-red-500": utilization >= 90,
});

const getFillRateClass = (diff) => ({
  "text-green-500": diff > 0,
  "text-red-500": diff < 0,
  "text-gray-500": diff === 0,
});

const getBBFactorClass = (factor) => ({
  "text-green-500": factor > 1,
  "text-red-500": factor < 1,
  "text-gray-500": factor === 1,
});

const fetchMetricsData = async () => {
  if (!selectedGroupId.value) return;

  try {
    const token = localStorage.getItem("adminToken");
    const response = await axios.get(
      `${API_BASE_URL}/argus/metrics?groupId=${
        selectedGroupId.value
      }&metrics=${selectedMetrics.value.join(",")}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    chartData.value = selectedMetrics.value.map((metric) => ({
      name: metrics.find((m) => m.value === metric).label,
      data: response.data[metric],
    }));
  } catch (error) {
    console.error("Error fetching metrics data:", error);
  }
};

const fetchSnapshots = async (setLoading = false) => {
  try {
    if (setLoading) store.commit("setLoading", true);
    const token = localStorage.getItem("adminToken");
    const response = await axios.get(`${API_BASE_URL}/argus/snapshot`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.data) {
      snapshots.value = response.data.map((snapshot) => ({
        ...snapshot,
        capUtilization:
          (snapshot.currentMetrics.daily.requests / snapshot.dailyRequestsCap) *
          100,
        fillRateDiff:
          ((snapshot.currentMetrics.rollingWindow.fillRate -
            snapshot.fillrateBenchmark) /
            snapshot.fillrateBenchmark) *
          100,
      }));
    }
  } catch (error) {
    console.error("Error fetching snapshots:", error);
  } finally {
    if (setLoading) store.commit("setLoading", false);
  }
};

watch([selectedGroupId, selectedMetrics], fetchMetricsData);

onMounted(() => {
  fetchSnapshots(true);
  updateInterval.value = setInterval(fetchSnapshots, 5000);
});

onUnmounted(() => {
  if (updateInterval.value) {
    clearInterval(updateInterval.value);
  }
});
</script>

<style scoped>
.text-green-500 {
  color: #22c55e;
}

.text-yellow-500 {
  color: #eab308;
}

.text-red-500 {
  color: #ef4444;
}

.text-gray-500 {
  color: #6b7280;
}
</style>
