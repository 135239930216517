<template>
  <div>
    <Filters @apply-filter="applyFilter" />
    <StatsCards />
    <CompareYesterdayGraph />
    <RevenuePieChart />
    <DailyTable />
    <GroupDailyTable />
    <DomainDailyTable />
    <TagDailyTable />
    <SellerDailyTable />
    <PartnerDailyTable />
    <DemandPartnerDaily />
    <PartnerMonthlyTable />
    <TagPartnerMonthly />
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import CompareYesterdayGraph from "./AdminNewDashboardComponents/CompareYesterdayGraph.vue";
import Filters from "./AdminNewDashboardComponents/Filters.vue";
import StatsCards from "./AdminNewDashboardComponents/StatsCards.vue";
import DailyTable from "./AdminNewDashboardComponents/tables/DailyTable.vue";
import DemandPartnerDaily from "./AdminNewDashboardComponents/tables/DemandPartnerDaily.vue";
import DomainDailyTable from "./AdminNewDashboardComponents/tables/DomainDailyTable.vue";
import GroupDailyTable from "./AdminNewDashboardComponents/tables/GroupDailyTable.vue";
import PartnerDailyTable from "./AdminNewDashboardComponents/tables/PartnerDailyTable.vue";
import PartnerMonthlyTable from "./AdminNewDashboardComponents/tables/PartnerMonthlyTable.vue";
import SellerDailyTable from "./AdminNewDashboardComponents/tables/SellerDailyTable.vue";
import TagDailyTable from "./AdminNewDashboardComponents/tables/TagDailyTable.vue";
import TagPartnerMonthly from "./AdminNewDashboardComponents/tables/TagPartnerMonthly.vue";
import RevenuePieChart from "./AdminNewDashboardComponents/RevenuePieChart.vue";

const store = useStore();

const applyFilter = () => {
  store.dispatch("AdminNewDashboardStore/applyFilter");
  setTimeout(() => {
    document
      .getElementById("publisher_daily_table")
      .scrollIntoView({ behavior: "smooth" });
  }, 100);
};
</script>
