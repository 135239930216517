<template>
  <Card class="my-3">
    <template #title>
      <div class="text-lg font-medium" id="publisher_daily_table">
        Publisher Daily
      </div>
    </template>
    <template #content>
      <div class="relative">
        <line-chart
          :data="filteredChartData"
          :options="chartOptions"
        ></line-chart>

        <LoaderSm v-if="chartLoader > 0" />
      </div>

      <!-- Dataset toggles -->
      <div class="mb-4">
        <label v-for="(dataset, index) in chartData" :key="index">
          <input type="checkbox" v-model="visibleDatasets" :value="index" />
          {{ dataset.name }}
        </label>
      </div>

      <DataTable
        :value="tableData"
        :defaultSortOrder="-1"
        class="p-datatable-striped p-datatable-sm"
        :paginator="true"
        :rows="10"
        :totalRecords="totalRecords"
        lazy
        @page="fetchData"
        @sort="fetchData"
        :loading="tableLoader > 0"
      >
        <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          sortable
        ></Column>
        <template #loadingicon>
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </template>
        <template #paginatorstart>
          <Button
            type="button"
            icon="pi pi-refresh"
            @click="fetchData({ page: 0, rows })"
            text
          />
        </template>
        <template #paginatorend>
          <download-csv :data="csvData" class="download-csv">
            <Button type="button" icon="pi pi-download" text />
          </download-csv>
        </template>
      </DataTable>
    </template>
  </Card>
</template>

<script setup>
import LoaderSm from "@/components/LoaderSm.vue";
import Button from "primevue/button";
import Card from "primevue/card";
import { onBeforeMount, ref } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const tableData = computed(
  () => store.state.AdminNewDashboardStore.dataTables.daily.tableData
);
const csvData = computed(
  () => store.state.AdminNewDashboardStore.dataTables.daily.csvData
);
const chartData = computed(
  () => store.state.AdminNewDashboardStore.dataTables.daily.chartData
);
const totalRecords = computed(
  () => store.state.AdminNewDashboardStore.dataTables.daily.totalRecords
);
const chartLoader = computed(
  () => store.state.AdminNewDashboardStore.loader.admin_daily_all
);
const tableLoader = computed(
  () => store.state.AdminNewDashboardStore.loader.admin_daily
);

const visibleDatasets = ref([0, 1, 2, 3]); // Initially show all datasets

const filteredChartData = computed(() =>
  chartData.value.filter((dataset, index) =>
    visibleDatasets.value.includes(index)
  )
);

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
    },
  },
  plugins: {
    legend: {
      display: false, // Hide default legend as we're using checkboxes
    },
  },
};

const columns = [
  { header: "Date", field: "date_trunc" },
  { header: "Player Loads", field: "loads" },
  { header: "Ad Impressions", field: "impressions" },
  { header: "Revenue", field: "revenue" },
  { header: "Cost", field: "cost" },
  { header: "Profit", field: "profit" },
  { header: "PM", field: "profit_margin" },
  { header: "RPM", field: "rpm" },
  { header: "CPM", field: "cpm" },
  { header: "RPL", field: "rpl" },
  { header: "CPL", field: "cpl" },
  { header: "Ad Play Rate", field: "fill_rate" },
];

const fetchData = ({
  page = 0,
  rows = 10,
  sortField,
  sortOrder,
  dataMode = "admin_daily",
}) => {
  store.dispatch("AdminNewDashboardStore/getTableData", {
    dataMode,
    page,
    rows,
    sortField,
    sortOrder,
  });
};

onBeforeMount(() => {
  fetchData({});
  fetchData({ dataMode: "admin_daily_all" });
});
</script>
