<template>
  <div>
    <h2 class="text-xl mb-3" style="color: var(--text-color)">
      Campaigns Management
    </h2>
    <Card>
      <template #header>
        <div
          class="flex flex-wrap justify-content-between align-items-center p-2 gap-2"
        >
          <div class="flex flex-wrap gap-2">
            <Button class="p-button-sm" @click="goToCreateNewCampaign()"
              >Add New Campaign</Button
            >
            <Button class="p-button-sm" @click="generateReport()"
              >Generate Report</Button
            >
          </div>
          <div class="flex flex-wrap gap-2">
            <Dropdown
              v-model="podcasterId"
              :options="podcasters"
              optionValue="podcaster_id"
              optionLabel="email"
              class="w-full sm:w-auto"
              placeholder="Select Podcaster"
            />
            <Dropdown
              v-model="showId"
              :options="shows"
              optionValue="show_id"
              optionLabel="show_title"
              class="w-full sm:w-auto"
              placeholder="All Shows"
            />
            <Calendar
              v-model="dateRange"
              selectionMode="range"
              :manualInput="false"
              class="w-full sm:w-auto"
            />
            <InputText
              class="w-full sm:w-auto"
              v-model="campaignsTableFilter.global.value"
              type="text"
              placeholder="Global Search"
            />
          </div>
        </div>
      </template>
      <template #content>
        <DataTable
          :value="campaigns"
          :paginator="true"
          :rows="10"
          :filters="campaignsTableFilter"
          class="p-datatable-sm"
          :stripedRows="true"
          :globalFilterFields="[
            'campaign_id',
            'show_title',
            'podcaster_email',
            'campaign_type',
            'status',
          ]"
        >
          <Column
            field="campaign_id"
            header="ID"
            :sortable="true"
            style="width: 5%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.campaign_id">
                {{ slotProps.data.campaign_id }}
              </div>
            </template>
          </Column>
          <Column
            field="show_title"
            header="Show Title"
            :sortable="true"
            style="width: 20%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.show_title">
                {{ slotProps.data.show_title }}
              </div>
            </template>
          </Column>
          <Column
            field="podcaster_email"
            header="Podcaster"
            :sortable="true"
            style="width: 15%"
          >
            <template #body="slotProps">
              <div
                class="truncate-text"
                :title="slotProps.data.podcaster_email"
              >
                {{ slotProps.data.podcaster_email }}
              </div>
            </template>
          </Column>
          <Column
            field="start_date"
            header="Start Date"
            :sortable="true"
            style="width: 10%"
          >
            <template #body="slotProps">
              <div class="date-cell" :title="slotProps.data.start_date">
                {{ slotProps.data.start_date }}
              </div>
            </template>
          </Column>
          <Column
            field="end_date"
            header="End Date"
            :sortable="true"
            style="width: 10%"
          >
            <template #body="slotProps">
              <div class="date-cell" :title="slotProps.data.end_date">
                {{ slotProps.data.end_date }}
              </div>
            </template>
          </Column>
          <Column
            field="promotions_per_interval"
            header="Interval"
            :sortable="true"
            style="width: 8%"
          ></Column>
          <Column
            field="campaign_type"
            header="Type"
            :sortable="true"
            style="width: 10%"
          ></Column>
          <Column
            field="ctr"
            header="CTR"
            :sortable="true"
            style="width: 7%"
          ></Column>
          <Column
            field="status"
            header="Status"
            :sortable="true"
            style="width: 8%"
          ></Column>
          <Column header="Actions" style="width: 7%">
            <template #body="slotProps">
              <div class="flex gap-1">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-sm p-button-outlined"
                  @click="goToUpdateCampaign(slotProps.data.campaign_id)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-sm p-button-outlined p-button-danger"
                  @click="confirmDeleteCampaign(slotProps.data.campaign_id)"
                />
              </div>
            </template>
          </Column>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchCampaigns()"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="campaignsForDownload" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import Column from "primevue/column";
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import moment from "moment";
import Calendar from "primevue/calendar";

const store = useStore();
const router = useRouter();
const confirm = useConfirm();
const toast = useToast();
const campaignsTableFilter = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const token = localStorage.getItem("podcastAdminToken");
const podcasterId = ref("podcastAdmin");
const showId = ref("podcastAdmin");
const dateRange = ref("Choose Data Range");
// const dates = ref([new Date(moment().subtract(1, "year")), new Date(moment())]);

const goToCreateNewCampaign = () => {
  router.push({ name: "AddPodcastAdminCampaign" });
};

const goToUpdateCampaign = (campaignId) => {
  router.push({
    name: "UpdatePodcastAdminCampaign",
    params: { campaignId },
  });
};

const confirmDeleteCampaign = (campaign_id) => {
  confirm.require({
    message: "Do you want to delete this campaign?",
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: () => {
      handleDeleteCampaign(campaign_id);
    },
  });
};

const handleDeleteCampaign = async (campaign_id) => {
  try {
    store.commit("setLoading", true);
    const response = await axios.delete(
      API_BASE_URL + "/campaigns/" + campaign_id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Campaign has been deleted`,
        life: 4000,
      });
      store.dispatch("loadCampaigns", { token });
    } else
      toast.add({
        severity: "error",
        summary: "Campaign not found",
        life: 4000,
      });
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Server error...",
      life: 4000,
    });
  }
  store.commit("setLoading", false);
};

const generateReport = async () => {
  try {
    store.commit("setLoading", true);
    const response = await axios.get(
      `${API_BASE_URL}/admin/reports/campaigns`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          dateRangeFrom: dateRange.value[0],
          dateRangeTo: dateRange.value[1],
          searchStr: campaignsTableFilter.value.global.value,
        },
        responseType: "blob", // Important: This tells axios to treat the response as binary data
      }
    );

    // Create a Blob from the response data
    const blob = new Blob([response.data], { type: "text/csv" });

    // Create a link element and trigger the download
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `campaigns_report_${moment().format(
      "YYYY-MM-DD_HH-mm-ss"
    )}.csv`;
    link.click();

    toast.add({
      severity: "success",
      summary: "Success",
      detail: "Report generated and downloaded successfully",
      life: 4000,
    });
  } catch (error) {
    console.error("Error generating report:", error);
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to generate report. Please try again.",
      life: 4000,
    });
  } finally {
    store.commit("setLoading", false);
  }
};

const campaigns = computed(() => {
  let tempCampaigns = store.getters.campaigns;
  if (!tempCampaigns) {
    tempCampaigns = [];
  }
  if (podcasterId.value != "podcastAdmin") {
    tempCampaigns = tempCampaigns.filter(
      (item) => item.podcaster_id == podcasterId.value
    );
  }

  if (showId.value != "podcastAdmin") {
    tempCampaigns = tempCampaigns.filter(
      (item) => item.show_id == showId.value
    );
  }

  if (
    dateRange.value != "Choose Data Range" &&
    dateRange.value.length === 2 &&
    dateRange.value[0] != null &&
    dateRange.value[1] != null &&
    moment(dateRange.value[0]).isBefore(dateRange.value[1])
  ) {
    tempCampaigns = tempCampaigns.filter(
      (item) =>
        moment(item.start_date).isAfter(dateRange.value[0]) &&
        moment(item.start_date).isBefore(
          moment(dateRange.value[1]).add(1, "days")
        )
    );
  }
  return tempCampaigns;
});

const campaignsForDownload = computed(() => {
  let tempArray = [];
  if (campaigns.value.length > 0) {
    campaigns.value.forEach((item) => {
      tempArray.push({
        "campaign ID": item.campaign_id,
        "Show Title": item.show_title,
        Podcaster: item.podcaster_name,
        "Podcaster Type": item.podcaster_type,
        category: item.categories,
        "RSS feed": item.rss_url,
        "campaign dates": item.start_date + " - " + item.end_date,
        Type: item.campaign_type,
        interval: item.promotions_per_interval,
        CTR: item.ctr,
        Hosted: item.hosted,
        status: item.status,
      });
    });
  }
  return tempArray;
});

const podcasters = computed(() => {
  let tempPodcasters = store.getters.podcasters;
  if (!tempPodcasters) {
    tempPodcasters = [];
  }
  tempPodcasters = tempPodcasters.map((item) => ({ ...item }));
  tempPodcasters.unshift({
    email: "All Podcasters",
    podcaster_id: "podcastAdmin",
  });
  return tempPodcasters;
});

const shows = computed(() => {
  let tempShowList = store.getters.shows;
  if (!tempShowList) {
    tempShowList = [];
  }
  tempShowList = tempShowList.map((item) => ({ ...item }));
  if (podcasterId.value != "podcastAdmin") {
    tempShowList = tempShowList.filter(
      (item) => item.podcaster_id == podcasterId.value
    );
  }
  tempShowList.unshift({
    show_title: "All Shows",
    show_id: "podcastAdmin",
  });
  return tempShowList;
});

const fetchCampaigns = async () => {
  if (!store.getters.campaigns || store.getters.campaigns.length == 0) {
    store.dispatch("loadCampaigns", { token });
  }
};

const fetchPodcasters = async () => {
  if (!store.getters.podcasters || store.getters.podcasters.length == 0) {
    store.dispatch("loadPodcasters", { token });
  }
};

const fetchShows = () => {
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
};

watch([podcasterId], (newValue, prevValue) => {
  showId.value = "podcastAdmin";
});

onMounted(() => {
  fetchShows();
  fetchPodcasters();
  fetchCampaigns();
  if (router.currentRoute.value.query.podcasterId) {
    podcasterId.value = router.currentRoute.value.query.podcasterId;
  }
  if (router.currentRoute.value.query.showId) {
    showId.value = router.currentRoute.value.query.showId;
  }
});
</script>

<style scoped>
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
  font-size: 0.9rem;
}

.p-card .p-card-body {
  padding: 0.5rem;
}

.p-dropdown,
.p-inputtext,
.p-button.p-button-sm,
.p-calendar {
  font-size: 0.9rem;
}

.p-button.p-button-sm {
  padding: 0.3rem 0.5rem;
}

.truncate-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.p-datatable .p-datatable-tbody > tr > td {
  max-height: 3.5em;
  overflow: hidden;
}
.date-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
