<template>
  <div class="grid mt-2 -mb-2">
    <div v-for="item in chartData" class="col-12 sm:col-6 md:col-4 relative">
      <Card>
        <template #title
          ><div class="text-lg font-medium">
            Revenue Per {{ item.title }}
          </div></template
        >
        <template #content>
          <pie-chart
            :data="item.data"
            :donut="true"
            prefix="$"
            :legend="false"
          ></pie-chart>
          <LoaderSm
            v-if="
              store.state.AdminNewDashboardStore.loader[
                `revenue_between_${item.key}`
              ] > 0
            "
          />
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeMount } from "vue";
import { useStore } from "vuex";
import Card from "primevue/card";
import LoaderSm from "@/components/LoaderSm.vue";

const store = useStore();

const chartData = computed(() => [
  {
    title: "Domain",
    key: "domain",
    data: store.state.AdminNewDashboardStore.revenueBetween.domain,
  },
  {
    title: "Geo",
    key: "geo",
    data: store.state.AdminNewDashboardStore.revenueBetween.geo,
  },
  {
    title: "Buyer",
    key: "tag_partner",
    data: store.state.AdminNewDashboardStore.revenueBetween.tag_partner,
  },
]);

onBeforeMount(() => {
  store.dispatch("AdminNewDashboardStore/getRevenueBetween", {
    selectField: "domain",
  });
  store.dispatch("AdminNewDashboardStore/getRevenueBetween", {
    selectField: "geo",
  });
  store.dispatch("AdminNewDashboardStore/getRevenueBetween", {
    selectField: "tag_partner",
  });
});
</script>
