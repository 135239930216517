<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">Podcasters Management</h2>
    <Card>
      <template #header>
        <div
          class="flex flex-wrap justify-content-between align-items-center p-2 gap-2"
        >
          <div class="flex flex-wrap gap-2">
            <Button class="p-button-sm" @click="goToCreateNewPodcaster()"
              >Add New Podcaster</Button
            >
            <Button class="p-button-sm" @click="showUploadCSVModal(true)"
              >Add Network</Button
            >
          </div>
          <InputText
            class="w-full sm:w-auto"
            type="text"
            v-model="podcastersTableFilter.global.value"
            placeholder="Global Search"
          />
        </div>
      </template>
      <template #content>
        <DataTable
          :value="podcasters"
          :paginator="true"
          :rows="10"
          :first="initFirst"
          @page="onChangePage"
          :filters="podcastersTableFilter"
          class="p-datatable-sm"
          :stripedRows="true"
          :globalFilterFields="[
            'id',
            'name',
            'email',
            'status',
            'podcaster_type',
            'plan',
            'utm_source',
          ]"
        >
          <Column field="id" header="ID" :sortable="true" style="width: 5%">
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.id">
                {{ slotProps.data.id }}
              </div>
            </template>
          </Column>
          <Column
            field="name"
            header="Name"
            :sortable="true"
            style="width: 15%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.name">
                {{ slotProps.data.name }}
              </div>
            </template>
          </Column>
          <Column
            field="email"
            header="Email"
            :sortable="true"
            style="width: 20%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.email">
                {{ slotProps.data.email }}
              </div>
            </template>
          </Column>
          <Column
            field="created_at"
            header="Created At"
            :sortable="true"
            style="width: 10%"
          >
            <template #body="slotProps">
              <div class="date-cell" :title="slotProps.data.created_at">
                {{ slotProps.data.created_at }}
              </div>
            </template>
          </Column>
          <Column
            field="status"
            header="Status"
            :sortable="true"
            style="width: 8%"
          ></Column>
          <Column
            field="podcaster_type"
            header="Type"
            :sortable="true"
            style="width: 8%"
          ></Column>
          <Column
            field="plan"
            header="Plan"
            :sortable="true"
            style="width: 8%"
          ></Column>
          <Column
            field="utm_source"
            header="UTM Source"
            :sortable="true"
            style="width: 10%"
          >
            <template #body="slotProps">
              <div class="truncate-text" :title="slotProps.data.utm_source">
                {{ slotProps.data.utm_source }}
              </div>
            </template>
          </Column>
          <Column header="Actions" style="width: 16%">
            <template #body="slotProps">
              <div class="flex gap-1">
                <Button
                  icon="pi pi-rss"
                  class="p-button-sm p-button-outlined"
                  @click="showRSSFeeds(slotProps.data.podcaster_id)"
                  title="View RSS Feeds"
                />
                <Button
                  icon="pi pi-list"
                  class="p-button-sm p-button-outlined"
                  @click="goToShows(slotProps.data.podcaster_id)"
                  title="Shows"
                />
                <Button
                  icon="pi pi-megaphone"
                  class="p-button-sm p-button-outlined"
                  @click="goToCampaigns(slotProps.data.podcaster_id)"
                  title="Campaigns"
                />
                <Button
                  icon="pi pi-pencil"
                  class="p-button-sm p-button-outlined"
                  @click="goToUpdatePodcaster(slotProps.data.podcaster_id)"
                  title="Edit"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-sm p-button-outlined p-button-danger"
                  @click="confirmDeletePodcaster(slotProps.data.podcaster_id)"
                  title="Delete"
                />
                <Button
                  icon="pi pi-download"
                  class="p-button-sm p-button-outlined"
                  @click="downloadReport(slotProps.data.podcaster_id)"
                  :loading="downloadingReports[slotProps.data.podcaster_id]"
                  :disabled="downloadingReports[slotProps.data.podcaster_id]"
                  title="Download Report"
                />
                <Button
                  icon="pi pi-eye"
                  class="p-button-sm p-button-outlined"
                  @click="goToPodcasterOverview(slotProps.data.podcaster_id)"
                  title="Podcaster Overview"
                />
                <Button
                  icon="pi pi-link"
                  class="p-button-sm p-button-outlined"
                  title="Get Affiliate Link"
                  @click="getAffiliateLink(slotProps.data.podcaster_id)"
                />
              </div>
            </template>
          </Column>
          <template #paginatorstart>
            <Button
              type="button"
              icon="pi pi-refresh"
              @click="fetchPodcasters()"
              text
            />
          </template>
          <template #paginatorend>
            <download-csv :data="podcastersDownload" class="download-csv">
              <Button type="button" icon="pi pi-download" text />
            </download-csv>
          </template> </DataTable
      ></template>
    </Card>
    <Dialog v-model:visible="openRssFeeds" header="RSS Feeds" modal>
      <div style="min-width: 200px">
        <p
          v-for="({ rss_url, author }, index) in podcasters.find(
            ({ podcaster_id }) => podcaster_id == modalPodcasterId
          ).rss_urls"
          :key="index"
        >
          <a :href="rss_url" target="_blank">
            {{ rss_url }}
          </a>
          ({{ author }})
        </p>
      </div>
    </Dialog>
    <Dialog
      v-model:visible="openUploadCSVModal"
      header="Upload CSV"
      modal
      :style="{ width: '20rem' }"
    >
      <p>Upload csv file to add network</p>
      <FileUpload
        class="w-full mt-4"
        ref="fileUpload"
        mode="basic"
        accept="text/csv"
        :maxFileSize="1000000"
        @select="onSelect"
        customUpload
      />
      <p v-if="showMessage.isShow" class="text-red-400">
        {{ showMessage.message }}
      </p>
      <download-csv :data="sampleCSVDownload" class="mt-2">
        (<span
          class="text-blue-500 hover:text-blue-300 underline cursor-pointer"
          >Download sample csv file</span
        >)
      </download-csv>
      <div class="mt-6 flex justify-content-between">
        <Button
          class="w-full sm:w-auto justify-content-center"
          severity="danger"
          @click="showUploadCSVModal(false)"
          >Cancel
        </Button>
        <Button
          class="w-full sm:w-auto justify-content-center"
          @click="submitCSV()"
          >Submit
        </Button>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import FileUpload from "primevue/fileupload";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onBeforeMount } from "vue";
import { FilterMatchMode } from "primevue/api";
import axios from "axios";
import { API_BASE_URL } from "@/consts";
import Dialog from "primevue/dialog";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import Papa from "papaparse";

let tempFirst = 0;
const initFirst = ref(Number(localStorage.getItem("first")));
const openRssFeeds = ref(false);
const openUploadCSVModal = ref(false);
const fileUpload = ref();
const showMessage = ref({
  isShow: false,
  message: "",
});
const store = useStore();
const toast = useToast();
const confirm = useConfirm();
const router = useRouter();
const modalPodcasterId = ref("");
const downloadingReports = ref({});
const token = localStorage.getItem("podcastAdminToken");
const podcastersTableFilter = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const sampleCSVDownload = ref([
  {
    name: "John Doe",
    email: "emailaddress@example.com",
    avg_per_month: 10000,
    rss: "https://samplesite.com/rss-feed-url-1",
  },
  {
    name: "",
    email: "",
    avg_per_month: 11000,
    rss: "https://samplesite.com/rss-feed-url-2",
  },
  {
    name: "",
    email: "",
    avg_per_month: 12000,
    rss: "https://samplesite.com/rss-feed-url-3",
  },
]);

const goToCreateNewPodcaster = () => {
  router.push({ name: "AddPodcaster" });
};
const goToUpdatePodcaster = (podcasterId) => {
  router.push({
    name: "UpdatePodcaster",
    params: { podcasterId },
  });
};

const goToPodcasterOverview = (podcasterId) => {
  router.push({ name: "PodcasterOverview", params: { podcasterId } });
};

const getAffiliateLink = (podcasterId) => {
  const link =
    "https://jamx.ai/podcasters-offer?utm_source=affiliate&utm_medium=affiliate&utm_campaign=" +
    podcasterId;

  navigator.clipboard.writeText(link).then(
    function () {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: "Affiliate link copied to clipboard",
        life: 4000,
      });
    },
    function (err) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Failed to copy affiliate link to clipboard",
        life: 4000,
      });
    }
  );
};

const goToShows = (podcasterId) => {
  router.push({
    name: "PodcastAdminShowsList",
    query: { podcasterId },
  });
};

const goToCampaigns = (podcasterId) => {
  localStorage.setItem("first", tempFirst);
  router.push({
    name: "PodcastAdminCampaignsList",
    query: { podcasterId },
  });
};

const onChangePage = (e) => {
  tempFirst = e.first;
  localStorage.removeItem("first");
};

const showUploadCSVModal = (flag) => {
  openUploadCSVModal.value = flag;
  showMessage.value.isShow = false;
};

const onSelect = () => {
  if (
    fileUpload.value.files.length > 0 ||
    fileUpload.value.files[0].type == "text/csv"
  )
    showMessage.value.isShow = false;
  else {
    showMessage.value.isShow = true;
    showMessage.value.message = "Please select CSV file";
  }
};

const submitCSV = async () => {
  if (
    fileUpload.value.files.length == 0 ||
    fileUpload.value.files[0].type != "text/csv"
  ) {
    showMessage.value.isShow = true;
    showMessage.value.message = "Please select CSV file";
    return;
  }
  try {
    let results = await parseCSV(fileUpload.value.files[0]);
    results = results.data.filter((result) => result.length == 4);
    if (results.length < 2 || results[0].length != 4) {
      showMessage.value.isShow = true;
      showMessage.value.message = "Wrong CSV format!";
      return;
    }
    let name = results[1][0];
    let email = results[1][1];
    let shows = [];
    for (let i = 1; i < results.length; i++) {
      shows.push({
        avg_per_month: results[i][2],
        rss: results[i][3],
      });
    }
    store.commit("setLoading", true);

    try {
      const response = await axios.post(
        API_BASE_URL + "/podcaster/csv-submit",
        {
          name,
          email,
          shows,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data?.success) {
        toast.add({
          severity: "success",
          summary: "Success",
          detail: `Shows created successfully`,
          life: 4000,
        });
        store.dispatch("loadPodcasters", { token });
        showUploadCSVModal();
      } else
        toast.add({
          severity: "error",
          summary: "Server error...",
          life: 4000,
        });
    } catch (error) {
      console.log(error);
      toast.add({
        severity: "error",
        summary: "Server error...",
        life: 4000,
      });
    }
  } catch (error) {
    console.error("Error parsing CSV:", error);
  }
  store.commit("setLoading", false);
};

const parseCSV = async (file) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      complete: function (results) {
        resolve(results);
      },
      error: function (error) {
        reject(error);
      },
    });
  });
};

const podcastersDownload = computed(() => {
  if (podcasters.value.length > 0) {
    return podcasters.value.map((item) => ({
      ...item,
      rss_urls: item.rss_urls
        ? item.rss_urls.map((r) => `${r.rss_url}(${r.author})`).join(",")
        : [],
    }));
  }
  return [];
});

const podcasters = computed(() => {
  let tempPodcasters = store.getters.podcasters;
  if (!tempPodcasters) {
    tempPodcasters = [];
  }
  if (store.getters.shows.length > 0) {
    tempPodcasters = tempPodcasters.map((item) => ({
      ...item,
      rss_urls: store.getters.shows
        .filter((show) => show.podcaster_id === item.podcaster_id)
        .map((show) => ({ rss_url: show.rss_url, author: show.author })),
    }));
  }
  return tempPodcasters;
});

const fetchShows = () => {
  if (!store.getters.shows || store.getters.shows.length == 0) {
    store.dispatch("loadShows", { token });
  }
};

const fetchPodcasters = async () => {
  if (!store.getters.podcasters || store.getters.podcasters.length == 0) {
    store.dispatch("loadPodcasters", { token });
  }

  console.log(
    store.getters.podcasters.filter((item) => item.email.includes("ilove"))
  );
};

const handleDeletePodcaster = async (podcaster_id) => {
  try {
    const response = await axios.delete(
      API_BASE_URL + "/podcaster/" + podcaster_id,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data?.success) {
      toast.add({
        severity: "success",
        summary: "Success",
        detail: `Podcaster has been deleted`,
        life: 4000,
      });
      store.dispatch("loadPodcasters", { token });
    } else
      toast.add({
        severity: "error",
        summary: "Podcaster not found",
        life: 4000,
      });
  } catch (error) {
    console.log(error);
    toast.add({
      severity: "error",
      summary: "Server error...",
      life: 4000,
    });
  }
};

const downloadReport = async (podcaster_id, format = "csv") => {
  let response;
  downloadingReports.value[podcaster_id] = true;

  try {
    response = await axios.get(
      `${API_BASE_URL}/admin/reports/performance/${podcaster_id}?format=${format}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );

    // Create a Blob from the response data
    const file = new Blob([response.data], {
      type: format === "pdf" ? "application/pdf" : "text/csv",
    });

    // Create a link element, hide it, direct it towards the blob, and then trigger a click
    const fileURL = URL.createObjectURL(file);
    const link = document.createElement("a");
    link.href = fileURL;
    link.download = `report_${podcaster_id}.${format}`;
    link.click();

    // Clean up by revoking the object URL
    URL.revokeObjectURL(fileURL);
  } catch (error) {
    console.error("Error downloading report: ", error);
    toast.add({
      severity: "error",
      summary: "Error downloading report",
      life: 4000,
    });
  } finally {
    downloadingReports.value[podcaster_id] = false;
  }
};

const confirmDeletePodcaster = (podcaster_id) => {
  confirm.require({
    message: "Do you want to delete this podcaster?",
    header: "Delete Confirmation",
    icon: "pi pi-info-circle",
    acceptClass: "p-button-danger",
    accept: () => {
      handleDeletePodcaster(podcaster_id);
    },
  });
};

const showRSSFeeds = (podcasterId) => {
  modalPodcasterId.value = podcasterId;
  openRssFeeds.value = true;
};

// const fetchShows = async () => {
//   try {
//     const response = await axios.get(API_BASE_URL + "/podcaster/shows/all", {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     if (response.data?.podcastShows?.length) {
//       for (let index = 0; index < podcasters.value.length; index++) {
//         const element = podcasters.value[index];
//         podcasters.value[index] = {
//           ...element,
//           rss_urls: response.data.podcastShows
//             .filter((item) => item.podcaster_id === element.podcaster_id)
//             .map((item) => item.rss_url),
//         };
//       }
//     }
//   } catch (error) {
//     console.error("Error fetching shows: ", error);
//   }
// };

onBeforeMount(() => {
  fetchPodcasters();
  fetchShows();
});
</script>

<style scoped>
.p-datatable .p-datatable-thead > tr > th,
.p-datatable .p-datatable-tbody > tr > td {
  padding: 0.5rem;
  font-size: 0.9rem;
}

.p-card .p-card-body {
  padding: 0.5rem;
}

.p-dropdown,
.p-inputtext,
.p-button.p-button-sm {
  font-size: 0.9rem;
}

.p-button.p-button-sm {
  padding: 0.3rem 0.5rem;
}

.truncate-text {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.date-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.p-datatable .p-datatable-tbody > tr > td {
  max-height: 3.5em;
  overflow: hidden;
}
</style>
