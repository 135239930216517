export default {
  loader: [],
  memberList: {
    partners: [],
    groups: [],
    sellers: [],
  },
  dateRange: [new Date(), new Date()],
  tagGroups: [],
  tags: [],
  memberFilter: {
    partners: [],
    groups: [],
    sellers: [],
    tags: [],
    tag_partners: [],
  },
  dailyPace: {
    today: {
      revenue: 0,
      impressions: 0,
      loads: 0,
    },
    yesterday: {
      revenue: 0,
      impressions: 0,
      loads: 0,
    },
  },
  mrr: {
    revenueSoFar: 0,
    averageDailyRevenue: 0,
    forecastedRevenue: 0,
    mrr: 0,
  },
  hourlyRevenue: {
    today: [],
    yesterday: [],
  },
  hourlyRevenueChartData: [],
  dataTables: {
    daily: {
      tableData: [],
      chartData: [],
      csvData: [],
      totalRecords: 0,
    },
    groupDaily: {
      tableData: [],
      chartData: [],
      csvData: [],
      totalRecords: 0,
    },
    domainDaily: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
    },
    tagDaily: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
    },
    sellerDaily: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
    },
    partnerDaily: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
      chartData: [],
    },
    partnerMonthly: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
    },
    tagPartnerMonthly: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
    },
    demandPartnerDaily: {
      tableData: [],
      csvData: [],
      totalRecords: 0,
    },
  },
  revenueBetween: {
    domain: [],
    geo: [],
    tag_partner: [],
  },
};
