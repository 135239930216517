import { formatCurrencyNumber } from "@/helpers";

export default {
  setLoader(state, { widgetName, isLoading }) {
    state.loader[widgetName] = state.loader[widgetName]
      ? state.loader[widgetName] + isLoading
      : isLoading;
  },
  setMemberList(state, memberListData) {
    state.memberList = { ...memberListData };
  },
  setTagGroups(state, tagGroupsData) {
    state.tagGroups = [...tagGroupsData];
  },
  setTags(state, tagsData) {
    state.tags = [...tagsData];
  },
  setDateRange(state, dateRange) {
    state.dateRange = [...dateRange];
  },
  setMemberFilter(state, memberFilter) {
    state.memberFilter = { ...memberFilter };
  },
  setDailyPace(state, { dailyPaceData }) {
    state.dailyPace = {
      today: dailyPaceData.todayResult[0],
      yesterday: dailyPaceData.yesterdayResult[0],
    };
  },
  setMRR(state, { mrrData }) {
    state.mrr = { ...mrrData };
  },
  setHourlyRevenue(state, { hourlyRevenueData }) {
    // Utility function to format hourly data
    const formatHourlyData = (data) =>
      data.map((item) => ({
        ...item,
        hour: new Date(item.hour).toISOString().substr(11, 5), // Extract HH:mm
      }));

    state.hourlyRevenue = {
      ...state.hourlyRevenue,
      today: formatHourlyData(hourlyRevenueData.todayResult),
      yesterday: formatHourlyData(hourlyRevenueData.yesterdayResult),
    };

    this.commit("AdminNewDashboardStore/setHourlyRevenueChartData", {
      hourlyRevenueData: state.hourlyRevenue,
    });
  },
  setHourlyRevenueChartData(state, { hourlyRevenueData }) {
    const transformedData = Object.keys(hourlyRevenueData).map((key) => ({
      name: key,
      data: hourlyRevenueData[key].reduce((acc, { hour, revenue }) => {
        acc[hour] = revenue; // Add each hour-revenue pair to the data object
        return acc;
      }, {}), // Start with an empty object for each key
    }));
    state.hourlyRevenueChartData = transformedData;
  },
  set_admin_daily(state, { data }) {
    if (!data?.adminDaily?.length) {
      state.dataTables.daily.tableData = [];
      state.dataTables.daily.totalRecords = 0;
    } else {
      state.dataTables.daily.totalRecords = parseInt(data.adminDaily[0].count);

      state.dataTables.daily.tableData = data.adminDaily.map((item) => {
        return {
          date_trunc: item.date_trunc.split("T")[0],
          loads: item.loads.toLocaleString(),
          impressions: item.impressions.toLocaleString(),
          revenue: formatCurrencyNumber(item.revenue),
          cost: formatCurrencyNumber(item.cost),
          profit: formatCurrencyNumber(item.profit),
          profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
          cpm: formatCurrencyNumber(item.cpm),
          rpm: formatCurrencyNumber(item.rpm),
          cpl: formatCurrencyNumber(item.cpl),
          rpl: formatCurrencyNumber(item.rpl),
          fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
        };
      });
    }
  },
  set_admin_daily_all(state, { data }) {
    if (!data?.adminDaily?.length) {
      state.dataTables.daily.chartData = [];
      state.dataTables.daily.csvData = [];
    } else {
      state.dataTables.daily.csvData = data.adminDaily;

      const loadsData = {};
      const impressionsData = {};
      const revenueData = {};
      const profitData = {};

      data.adminDaily.forEach((item) => {
        const date = item.date_trunc.split("T")[0];
        loadsData[date] = item.loads;
        impressionsData[date] = item.impressions;
        revenueData[date] = parseFloat(item.revenue.toFixed(2));
        profitData[date] = parseFloat(item.profit.toFixed(2));
      });

      state.dataTables.daily.chartData = [
        { name: "Loads", data: loadsData },
        { name: "Impressions", data: impressionsData },
        { name: "Revenue", data: revenueData },
        { name: "Profit", data: profitData },
      ];
    }
  },
  set_admin_per_group_daily(state, { data }) {
    if (!data?.adminPerGroupDaily?.length) {
      state.dataTables.groupDaily.tableData = [];
      state.dataTables.groupDaily.totalRecords = 0;
    } else {
      state.dataTables.groupDaily.totalRecords = parseInt(
        data.adminPerGroupDaily[0].count
      );

      state.dataTables.groupDaily.tableData = data.adminPerGroupDaily.map(
        (item) => {
          return {
            date_trunc: item.date_trunc.split("T")[0],
            group_name: item.group_name,
            loads: item.loads.toLocaleString(),
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            cost: formatCurrencyNumber(item.cost),
            profit: formatCurrencyNumber(item.profit),
            profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
            cpm: formatCurrencyNumber(item.cpm),
            rpm: formatCurrencyNumber(item.rpm),
            cpl: formatCurrencyNumber(item.cpl),
            rpl: formatCurrencyNumber(item.rpl),
            fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
          };
        }
      );
    }
  },
  set_admin_per_group_daily_all(state, { data }) {
    if (!data?.adminPerGroupDaily?.length) {
      state.dataTables.groupDaily.chartData = [];
      state.dataTables.groupDaily.csvData = [];
    } else {
      state.dataTables.groupDaily.csvData = data.adminPerGroupDaily;

      const perGroupChartData = {};

      let perGroupDailyChart = data.adminPerGroupDaily;

      perGroupDailyChart = perGroupDailyChart.map((item) => {
        return {
          date_trunc: item.date_trunc.split("T")[0],
          group_name: item.group_name,
          loads: item.loads.toLocaleString(),
          impressions: item.impressions.toLocaleString(),
          revenue: formatCurrencyNumber(item.revenue),
          cost: formatCurrencyNumber(item.cost),
          profit: formatCurrencyNumber(item.profit),
          profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
          cpm: formatCurrencyNumber(item.cpm),
          rpm: formatCurrencyNumber(item.rpm),
          cpl: formatCurrencyNumber(item.cpl),
          rpl: formatCurrencyNumber(item.rpl),
          fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
        };
      });

      for (let i = 0; i < perGroupDailyChart.length; i++) {
        const domainData = perGroupDailyChart[i];

        if (!perGroupChartData[domainData.group_name]) {
          perGroupChartData[domainData.group_name] = {};
        }

        perGroupChartData[domainData.group_name][
          domainData.date_trunc.split("T")[0]
        ] = domainData.revenue;
      }

      state.dataTables.groupDaily.chartData = Object.entries(
        perGroupChartData
      ).map(([group_name, data]) => ({
        name: group_name,
        data: Object.entries(data).reduce((acc, [date, price]) => {
          acc[date] = parseFloat(price.replace(/[$,]/g, ""));
          return acc;
        }, {}),
      }));
    }
  },
  set_admin_per_domain_daily(state, { data }) {
    if (!data?.adminPerDomainDaily?.length) {
      state.dataTables.domainDaily.tableData = [];
      state.dataTables.domainDaily.totalRecords = 0;
    } else {
      state.dataTables.domainDaily.totalRecords = parseInt(
        data.adminPerDomainDaily[0].count
      );

      state.dataTables.domainDaily.tableData = data.adminPerDomainDaily.map(
        (item) => {
          return {
            date_trunc: item.date_trunc.split("T")[0],
            url: item.url,
            loads: item.loads.toLocaleString(),
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            cost: formatCurrencyNumber(item.cost),
            profit: formatCurrencyNumber(item.profit),
            profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
            cpm: formatCurrencyNumber(item.cpm),
            rpm: formatCurrencyNumber(item.rpm),
            cpl: formatCurrencyNumber(item.cpl),
            rpl: formatCurrencyNumber(item.rpl),
            fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
          };
        }
      );
    }
  },
  set_admin_per_domain_daily_all(state, { data }) {
    if (!data?.adminPerDomainDaily?.length) {
      state.dataTables.domainDaily.csvData = [];
    } else state.dataTables.domainDaily.csvData = data.adminPerDomainDaily;
  },
  set_admin_per_tag_daily(state, { data }) {
    if (!data?.adminPerTagDaily?.length) {
      state.dataTables.tagDaily.tableData = [];
      state.dataTables.tagDaily.totalRecords = 0;
    } else {
      state.dataTables.tagDaily.totalRecords = parseInt(
        data.adminPerTagDaily[0].count
      );

      state.dataTables.tagDaily.tableData = data.adminPerTagDaily.map(
        (item) => {
          return {
            date_trunc: item.date_trunc,
            tag_id: item.tag_id,
            tag_name: item.tag_name,
            tag_partner: item.tag_partner,
            geo: item.geo,
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            requests: item.requests.toLocaleString(),
            fill_rate: `${
              item.requests == 0
                ? 0
                : ((item.impressions / item.requests) * 100).toFixed(2)
            }%`,
          };
        }
      );
    }
  },
  set_admin_per_tag_daily_all(state, { data }) {
    if (!data?.adminPerTagDaily?.length) {
      state.dataTables.tagDaily.csvData = [];
    } else state.dataTables.tagDaily.csvData = data.adminPerTagDaily;
  },
  set_admin_per_seller_daily(state, { data }) {
    if (!data?.adminPerSellerDaily?.length) {
      state.dataTables.sellerDaily.tableData = [];
      state.dataTables.sellerDaily.totalRecords = 0;
    } else {
      state.dataTables.sellerDaily.totalRecords = parseInt(
        data.adminPerSellerDaily[0].count
      );

      state.dataTables.sellerDaily.tableData = data.adminPerSellerDaily.map(
        (item) => {
          return {
            date_trunc: item.date_trunc.split("T")[0],
            loads: item.loads.toLocaleString(),
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            cost: formatCurrencyNumber(item.cost),
            profit: formatCurrencyNumber(item.profit),
            cpm: formatCurrencyNumber(item.cpm),
            rpm: formatCurrencyNumber(item.rpm),
            cpl: formatCurrencyNumber(item.cpl),
            rpl: formatCurrencyNumber(item.rpl),
            fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
            seller_id: item.seller_id,
            seller_name: item.seller_name,
          };
        }
      );
    }
  },
  set_admin_per_seller_daily_all(state, { data }) {
    if (!data?.adminPerSellerDaily?.length) {
      state.dataTables.sellerDaily.csvData = [];
    } else state.dataTables.sellerDaily.csvData = data.adminPerSellerDaily;
  },
  set_admin_per_partner_daily(state, { data }) {
    if (!data?.adminPerPartnerDaily?.length) {
      state.dataTables.partnerDaily.tableData = [];
      state.dataTables.partnerDaily.totalRecords = 0;
    } else {
      state.dataTables.partnerDaily.totalRecords = parseInt(
        data.adminPerPartnerDaily[0].count
      );

      state.dataTables.partnerDaily.tableData = data.adminPerPartnerDaily.map(
        (item) => {
          return {
            date_trunc: item.date_trunc.split("T")[0],
            partner_name: item.partner_name,
            loads: item.loads.toLocaleString(),
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            cost: formatCurrencyNumber(item.cost),
            profit: formatCurrencyNumber(item.profit),
            profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
            cpm: formatCurrencyNumber(item.cpm),
            rpm: formatCurrencyNumber(item.rpm),
            cpl: formatCurrencyNumber(item.cpl),
            rpl: formatCurrencyNumber(item.rpl),
            fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
          };
        }
      );
    }
  },
  set_admin_per_partner_daily_all(state, { data }) {
    if (!data?.adminPerPartnerDaily?.length) {
      state.dataTables.partnerDaily.csvData = [];
      state.dataTables.partnerDaily.chartData = 0;
    } else {
      state.dataTables.partnerDaily.csvData = data.adminPerPartnerDaily;
      const perPartnerChartData = {};

      let perPartnerDailyChart = data.adminPerPartnerDaily;

      perPartnerDailyChart = perPartnerDailyChart.map((item) => {
        return {
          date_trunc: item.date_trunc.split("T")[0],
          partner_name: item.partner_name,
          loads: item.loads.toLocaleString(),
          impressions: item.impressions.toLocaleString(),
          revenue: formatCurrencyNumber(item.revenue),
          cost: formatCurrencyNumber(item.cost),
          profit: formatCurrencyNumber(item.profit),
          profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
          cpm: formatCurrencyNumber(item.cpm),
          rpm: formatCurrencyNumber(item.rpm),
          cpl: formatCurrencyNumber(item.cpl),
          rpl: formatCurrencyNumber(item.rpl),
          fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
        };
      });

      for (let i = 0; i < perPartnerDailyChart.length; i++) {
        const domainData = perPartnerDailyChart[i];

        if (!perPartnerChartData[domainData.partner_name]) {
          perPartnerChartData[domainData.partner_name] = {};
        }

        perPartnerChartData[domainData.partner_name][
          domainData.date_trunc.split("T")[0]
        ] = domainData.revenue;
      }

      state.dataTables.partnerDaily.chartData = Object.entries(
        perPartnerChartData
      ).map(([partner_name, data]) => ({
        name: partner_name,
        data: Object.entries(data).reduce((acc, [date, price]) => {
          acc[date] = parseFloat(price.replace(/[$,]/g, ""));
          return acc;
        }, {}),
      }));
    }
  },
  set_admin_per_partner_monthly(state, { data }) {
    if (!data?.adminPerPartnerMonthly?.length) {
      state.dataTables.partnerMonthly.tableData = [];
      state.dataTables.partnerMonthly.totalRecords = 0;
    } else {
      state.dataTables.partnerMonthly.totalRecords = parseInt(
        data.adminPerPartnerMonthly[0].count
      );

      state.dataTables.partnerMonthly.tableData =
        data.adminPerPartnerMonthly.map((item) => {
          return {
            date_trunc: item.date_trunc,
            partner_name: item.partner_name,
            loads: item.loads.toLocaleString(),
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            cost: formatCurrencyNumber(item.cost),
            profit: formatCurrencyNumber(item.profit),
            profit_margin: `${(item.profit_margin * 100).toFixed(0)}%`,
            cpm: formatCurrencyNumber(item.cpm),
            rpm: formatCurrencyNumber(item.rpm),
            cpl: formatCurrencyNumber(item.cpl),
            rpl: formatCurrencyNumber(item.rpl),
            fill_rate: `${(item.fill_rate * 100).toFixed(0)}%`,
          };
        });
    }
  },
  set_admin_per_partner_monthly_all(state, { data }) {
    if (!data?.adminPerPartnerMonthly?.length) {
      state.dataTables.partnerMonthly.csvData = [];
    } else
      state.dataTables.partnerMonthly.csvData = data.adminPerPartnerMonthly;
  },
  set_admin_per_tagpartner_monthly(state, { data }) {
    if (!data?.adminPerTagpartnerMonthly?.length) {
      state.dataTables.tagPartnerMonthly.tableData = [];
      state.dataTables.tagPartnerMonthly.totalRecords = 0;
    } else {
      state.dataTables.tagPartnerMonthly.totalRecords = parseInt(
        data.adminPerTagpartnerMonthly[0].count
      );

      state.dataTables.tagPartnerMonthly.tableData =
        data.adminPerTagpartnerMonthly.map((item) => {
          return {
            date_trunc: item.date_trunc.split("T")[0],
            tag_partner: item.tag_partner,
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            requests: item.requests.toLocaleString(),
            fill_rate: `${
              item.requests == 0
                ? 0
                : ((item.impressions / item.requests) * 100).toFixed(2)
            }%`,
          };
        });
    }
  },
  set_admin_per_tagpartner_monthly_all(state, { data }) {
    if (!data?.adminPerTagpartnerMonthly?.length) {
      state.dataTables.tagPartnerMonthly.csvData = [];
    } else
      state.dataTables.tagPartnerMonthly.csvData =
        data.adminPerTagpartnerMonthly;
  },
  set_admin_per_tagpartner_daily(state, { data }) {
    if (!data?.adminPerTagpartnerDaily?.length) {
      state.dataTables.demandPartnerDaily.tableData = [];
      state.dataTables.demandPartnerDaily.totalRecords = 0;
    } else {
      state.dataTables.demandPartnerDaily.totalRecords = parseInt(
        data.adminPerTagpartnerDaily[0].count
      );

      state.dataTables.demandPartnerDaily.tableData =
        data.adminPerTagpartnerDaily.map((item) => {
          return {
            date_trunc: item.date_trunc,
            tag_partner: item.tag_partner,
            geo: item.geo,
            impressions: item.impressions.toLocaleString(),
            revenue: formatCurrencyNumber(item.revenue),
            requests: item.requests.toLocaleString(),
            fill_rate: `${
              item.requests == 0
                ? 0
                : ((item.impressions / item.requests) * 100).toFixed(2)
            }%`,
          };
        });
    }
  },
  set_admin_per_tagpartner_daily_all(state, { data }) {
    if (!data?.adminPerTagpartnerDaily?.length) {
      state.dataTables.demandPartnerDaily.csvData = [];
    } else
      state.dataTables.demandPartnerDaily.csvData =
        data.adminPerTagpartnerDaily;
  },
  set_revenue_between(state, { data, selectField }) {
    const convertToPairArray = (array) => {
      return array.map((item) => {
        const values = Object.values(item); // Extract all values from the object
        return values.slice(0, 2); // Take the first two values
      });
    };
    state.revenueBetween[selectField] = convertToPairArray(data);
  },
};
