<template>
  <div class="mt-2 relative">
    <Card>
      <template #title
        ><span class="text-lg font-medium">VS Yesterday</span></template
      >
      <template #content>
        <line-chart
          :data="store.state.AdminNewDashboardStore.hourlyRevenueChartData"
          prefix="$"
          :precision="5"
        ></line-chart>
        <LoaderSm
          v-if="store.state.AdminNewDashboardStore.loader.hourlyRevenue > 0"
        />
      </template>
    </Card>
  </div>
</template>

<script setup>
import LoaderSm from "@/components/LoaderSm.vue";
import Card from "primevue/card";
import { onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();

onBeforeMount(() => {
  store.dispatch("AdminNewDashboardStore/getHourlyRevenue");
});
</script>
