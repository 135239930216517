<template>
  <div class="grid">
    <div
      v-for="cardItem in dailyPaceCardOptions"
      class="col-12 sm:col-6 lg:col-3"
    >
      <Card>
        <template #title
          ><div class="text-lg font-medium">
            {{ cardItem.title }} Today
          </div></template
        >
        <template #content
          ><div class="relative">
            <Skeleton
              v-if="store.state.AdminNewDashboardStore.loader['dailyPace'] > 0"
              class="absolute top-0 left-0 w-full h-full"
            ></Skeleton>
            <div
              class="text-3xl line-height-1 -mt-3"
              :class="
                store.state.AdminNewDashboardStore.loader['dailyPace'] > 0 &&
                'opacity-0'
              "
            >
              {{
                cardItem.itemName == "revenue"
                  ? formatMoney(
                      store.state.AdminNewDashboardStore.dailyPace.today[
                        cardItem.itemName
                      ]
                    )
                  : store.state.AdminNewDashboardStore.dailyPace.today[
                      cardItem.itemName
                    ]
              }}
            </div>
            <div
              class="-mb-3 mt-2 flex align-items-center gap-1 font-medium"
              :class="`${
                store.state.AdminNewDashboardStore.loader['dailyPace'] > 0 &&
                'opacity-0'
              } ${
                percentageDiff(cardItem.itemName) >= 0
                  ? 'text-teal-400'
                  : 'text-red-400'
              }`"
            >
              <i
                :class="`pi ${
                  percentageDiff(cardItem.itemName) >= 0
                    ? 'pi-angle-up'
                    : 'pi-angle-down'
                }`"
              ></i>
              <span>{{ percentageDiff(cardItem.itemName).toFixed(2) }}%</span>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div class="col-12 sm:col-6 lg:col-3">
      <Card>
        <template #title
          ><div class="text-lg font-medium">Monthly Run Rate</div></template
        >
        <template #content
          ><div class="relative">
            <Skeleton
              v-if="store.state.AdminNewDashboardStore.loader['mrr'] > 0"
              class="absolute top-0 left-0 w-full h-full"
            ></Skeleton>
            <div
              class="text-3xl line-height-1 -mt-3"
              :class="
                store.state.AdminNewDashboardStore.loader['mrr'] > 0 &&
                'opacity-0'
              "
            >
              {{ formatMoney(store.state.AdminNewDashboardStore.mrr.mrr) }}
            </div>
            <div
              class="-mb-3 mt-2 text-bluegray-500"
              :class="
                store.state.AdminNewDashboardStore.loader['mrr'] > 0 &&
                'opacity-0'
              "
            >
              <span class="text-sm">Average Per Day: </span>
              <span class="font-medium">{{
                formatMoney(
                  store.state.AdminNewDashboardStore.mrr.averageDailyRevenue
                )
              }}</span>
            </div>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup>
import { formatMoney } from "@/helpers";
import Card from "primevue/card";
import { onBeforeMount } from "vue";
import { useStore } from "vuex";
import Skeleton from "primevue/skeleton";

const store = useStore();

const percentageDiff = (itemName) => {
  const valueToday =
    store.state.AdminNewDashboardStore.dailyPace.today[itemName];
  const valueYesterday =
    store.state.AdminNewDashboardStore.dailyPace.yesterday[itemName];
  return ((valueToday - valueYesterday) / valueYesterday) * 100;
};

const dailyPaceCardOptions = [
  {
    title: "Revenue",
    itemName: "revenue",
  },
  {
    title: "Impressions",
    itemName: "impressions",
  },
  {
    title: "Loads",
    itemName: "loads",
  },
];

onBeforeMount(() => {
  store.dispatch("AdminNewDashboardStore/getDailyPace");
  store.dispatch("AdminNewDashboardStore/getMRR");
});
</script>
