<template>
  <v-card class="daily-list elevation-2 rounded-lg">
    <v-card-title class="d-flex justify-space-between align-center pa-4">
      <span class="text-h6 font-weight-medium">Daily by Campaign Type</span>
      <v-btn
        icon="mdi-refresh"
        variant="text"
        @click="refreshData"
        :loading="loading"
      ></v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <div class="daily-list-content pa-4">
      <v-skeleton-loader v-if="loading" type="table" />
      <DataTable
        v-else
        :value="formattedData"
        :globalFilterFields="['show_id']"
        v-model:filters="filters"
        filterDisplay="row"
        :paginator="true"
        :rows="10"
        :rowsPerPageOptions="[5, 10, 20, 50]"
        class="custom-datatable"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        :rowHover="true"
        responsiveLayout="scroll"
      >
        <Column field="date" header="Date" />
        <Column
          field="campaign_type"
          header="Campaign Type"
          :showFilterMenu="false"
          :filterMenuStyle="{ width: '12rem' }"
          style="min-width: 10rem"
        >
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              @change="filterCallback()"
              :options="campaignTypes"
              optionLabel="label"
              optionValue="value"
              placeholder="Select One"
              class="p-column-filter"
              style="min-width: 10rem"
              :showClear="true"
            />
          </template>
        </Column>
        <Column field="show_title" header="Show" />
        <Column field="views" header="Views" />
        <Column field="plays" header="Downloads" />
        <Column field="listeners" header="Unique Listeners" />
        <Column field="ctr" header="CTR" />
        <template #paginatorstart></template>
        <template #paginatorend>
          <download-csv :data="formattedData" class="download-csv">
            <Button type="button" icon="pi pi-download" text />
          </download-csv>
        </template>
      </DataTable>
    </div>
  </v-card>
</template>

<script setup>
import { defineProps, computed } from "vue";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import { ref } from "vue";
import { FilterMatchMode } from "primevue/api";

const props = defineProps({
  loading: Boolean,
  showId: String,
  data: Array,
});

const emit = defineEmits(["refreshData"]);

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  show_title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
  campaign_type: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const campaignTypes = ref([
  {
    label: "Publisher",
    value: "publisher",
  },
  {
    label: "Promotion",
    value: "promotion",
  },
  {
    label: "Spotify",
    value: "spotify",
  },
]);

const formattedData = computed(() => {
  return props.data.map((item) => ({
    ...item,
    date: item.date.split("T")[0],
    views: formatNumber(item.views),
    plays: formatNumber(item.plays),
    listeners: formatNumber(item.listeners),
    ctr: formatCTR(item.ctr),
  }));
});

const formatNumber = (num) => {
  return new Intl.NumberFormat("en-US").format(num);
};

const formatCTR = (ctr) => {
  return Number(ctr).toFixed(3);
};

const refreshData = () => {
  console.log("REFRESH");
  emit("refreshData");
};
</script>

<style scoped>
.daily-list {
  background: var(--overview-card-bg);
  transition: box-shadow 0.3s ease;
}

.daily-list:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1) !important;
}

.daily-list-content {
  min-height: 150px;
  position: relative;
}
</style>

<style>
.custom-datatable .p-datatable-thead > tr > th,
.custom-datatable .p-datatable-tbody > tr > td,
.custom-datatable .p-paginator {
  background-color: var(--overview-card-bg);
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
}

.custom-datatable .p-datatable-tbody > tr {
  height: 48px;
}

.custom-datatable .p-paginator {
  border: none;
  padding: 0.5rem;
}

.custom-datatable .p-paginator .p-paginator-element {
  min-width: 2rem;
  height: 2rem;
}

.custom-datatable .p-datatable-thead > tr > th,
.custom-datatable .p-datatable-tbody > tr > td {
  border-color: var(--surface-50);
}

.custom-datatable .p-datatable-tbody > tr:hover {
  background-color: var(--surface-100);
}

.custom-datatable .p-column-header-content {
  font-weight: 600;
}

.v-card-title {
  background: var(--overview-card-bg);
  color: var(--text-color);
  font-size: 1.25rem;
  font-weight: 500;
}
</style>
