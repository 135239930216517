<template>
  <div>
    <h2 class="mt-0" style="color: var(--text-color)">
      {{ isEditing ? "Edit Content Pack" : "Add New Content Pack" }}
    </h2>
    <Card>
      <template #header>
        <div class="p-3 pb-0 flex flex-column gap-3">
          <div
            class="flex flex-column sm:flex-row justify-content-between gap-3"
          >
            <InputText
              class="w-full sm:w-20rem"
              v-model="contentPackName"
              type="text"
              placeholder="Content Pack Name"
            />
            <Button
              class="justify-content-center"
              @click="saveContentPack"
              :loading="loading"
            >
              {{ isEditing ? "Update" : "Create" }} Content Pack
            </Button>
          </div>

          <!-- Search and Filters Section -->
          <div class="flex flex-column sm:flex-row gap-3">
            <span class="p-input-icon-left flex-grow-1">
              <i class="pi pi-search" />
              <InputText
                v-model="globalSearchValue"
                class="w-full"
                placeholder="Search shows..."
              />
            </span>
            <MultiSelect
              v-model="selectedCategories"
              :options="availableCategories"
              placeholder="Filter Categories"
              class="w-full sm:w-15rem"
            />
            <MultiSelect
              v-model="selectedHosted"
              :options="availableHosted"
              placeholder="Filter Hosted"
              class="w-full sm:w-15rem"
            />
          </div>

          <div class="flex gap-2">
            <Button
              type="button"
              label="Add All Filtered"
              @click="addAllFiltered"
              size="small"
              outlined
            />
            <Button
              type="button"
              label="Remove All"
              @click="removeAllShows"
              size="small"
              outlined
            />
          </div>
        </div>
      </template>

      <template #content>
        <!-- Selected Shows Table -->
        <div class="mb-4">
          <h3>Shows in Content Pack</h3>
          <DataTable
            :value="selectedShowsList"
            :paginator="true"
            :rows="20"
            class="p-datatable-sm"
            :striped-rows="true"
            :loading="loading"
            sortField="show_title"
            :sortOrder="1"
          >
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-minus"
                  class="p-button-rounded p-button-danger p-button-sm"
                  @click="removeShow(slotProps.data)"
                />
              </template>
            </Column>
            <Column
              field="show_id"
              header="ID"
              :sortable="true"
              style="max-width: 100px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.show_id }}</div>
              </template>
            </Column>
            <Column
              field="show_title"
              header="Title"
              :sortable="true"
              style="max-width: 300px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.show_title }}</div>
              </template>
            </Column>
            <Column
              field="hosted"
              header="Hosted"
              :sortable="true"
              style="max-width: 150px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.hosted }}</div>
              </template>
            </Column>
            <Column
              field="categories"
              header="Categories"
              :sortable="true"
              style="max-width: 200px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.categories }}</div>
              </template>
            </Column>
            <Column
              field="rss_url"
              header="RSS Link"
              :sortable="true"
              style="max-width: 200px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.rss_url }}</div>
              </template>
            </Column>
            <Column header="Included In Content Packs" :sortable="false">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-1">
                  <Tag
                    v-for="pack in showContentPacks[slotProps.data.show_id]"
                    :key="pack.id"
                    :value="pack.name"
                    severity="info"
                    class="text-xs"
                  />
                  <span
                    v-if="!showContentPacks[slotProps.data.show_id]?.length"
                  >
                    None
                  </span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>

        <!-- Available Shows Table -->
        <div>
          <h3>Available Shows</h3>
          <DataTable
            :value="availableShowsList"
            :paginator="true"
            :rows="20"
            class="p-datatable-sm"
            :striped-rows="true"
            :loading="loading"
            sortField="show_title"
            :sortOrder="1"
          >
            <Column>
              <template #body="slotProps">
                <Button
                  icon="pi pi-plus"
                  class="p-button-rounded p-button-success p-button-sm"
                  @click="addShow(slotProps.data)"
                />
              </template>
            </Column>
            <Column
              field="show_id"
              header="ID"
              :sortable="true"
              style="max-width: 100px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.show_id }}</div>
              </template>
            </Column>
            <Column
              field="show_title"
              header="Title"
              :sortable="true"
              style="max-width: 300px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.show_title }}</div>
              </template>
            </Column>
            <Column
              field="hosted"
              header="Hosted"
              :sortable="true"
              style="max-width: 150px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.hosted }}</div>
              </template>
            </Column>
            <Column
              field="categories"
              header="Categories"
              :sortable="true"
              style="max-width: 200px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.categories }}</div>
              </template>
            </Column>
            <Column
              field="rss_url"
              header="RSS Link"
              :sortable="true"
              style="max-width: 200px"
            >
              <template #body="slotProps">
                <div class="truncate">{{ slotProps.data.rss_url }}</div>
              </template>
            </Column>
            <Column header="Included In Content Packs" :sortable="false">
              <template #body="slotProps">
                <div class="flex flex-wrap gap-1">
                  <Tag
                    v-for="pack in showContentPacks[slotProps.data.show_id]"
                    :key="pack.id"
                    :value="pack.name"
                    severity="info"
                    class="text-xs"
                  />
                  <span
                    v-if="!showContentPacks[slotProps.data.show_id]?.length"
                  >
                    None
                  </span>
                </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import axios from "axios";
import { API_BASE_URL } from "../consts.js";
import { useToast } from "primevue/usetoast";
import Card from "primevue/card";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import MultiSelect from "primevue/multiselect";
import Tag from "primevue/tag";

const toast = useToast();
const store = useStore();
const router = useRouter();
const route = useRoute();
const contentPackName = ref("");
const selectedShows = ref([]);
const loading = ref(false);
const selectedCategories = ref([]);
const selectedHosted = ref([]);
const globalSearchValue = ref("");
const contentPacks = ref([]);
const showContentPacks = ref({});
const selectedContentPackFilters = ref([]);

const token = localStorage.getItem("adminToken");
const packId = computed(() => route.params.id);
const isEditing = computed(() => !!packId.value);
const shows = computed(() => store.getters.shows);

// Add computed property for content pack filter options
const contentPackFilterOptions = computed(() => {
  const options = [
    { id: "none", name: "None" }, // Special option for shows not in any pack
  ];

  // Add all content packs as options
  contentPacks.value.forEach((pack) => {
    options.push({
      id: pack.id,
      name: pack.name,
    });
  });

  return options;
});

// Existing computed properties
const availableCategories = computed(() => {
  const categoriesSet = new Set();
  shows.value.forEach((show) => {
    if (show.categories) {
      show.categories.split(",").forEach((category) => {
        categoriesSet.add(category.trim());
      });
    }
  });
  return Array.from(categoriesSet).sort();
});

const availableHosted = computed(() => {
  return [
    ...new Set(shows.value.map((show) => show.hosted).filter(Boolean)),
  ].sort();
});

// Modified filteredShows to include content pack filtering
const filteredShows = computed(() => {
  let filtered = [...shows.value];

  // Apply global search
  if (globalSearchValue.value) {
    const searchTerm = globalSearchValue.value.toLowerCase();
    filtered = filtered.filter((show) => {
      return (
        show.show_title?.toLowerCase().includes(searchTerm) ||
        show.hosted?.toLowerCase().includes(searchTerm) ||
        show.categories?.toLowerCase().includes(searchTerm) ||
        show.rss_url?.toLowerCase().includes(searchTerm)
      );
    });
  }

  // Apply content pack filter
  if (selectedContentPackFilters.value.length > 0) {
    filtered = filtered.filter((show) => {
      const showPacks = showContentPacks.value[show.show_id] || [];

      return selectedContentPackFilters.value.some((filterPack) => {
        if (filterPack.id === "none") {
          // Show matches if it's not in any content pack
          return showPacks.length === 0;
        } else {
          // Show matches if it's in this content pack
          return showPacks.some((pack) => pack.id === filterPack.id);
        }
      });
    });
  }

  // Apply category filter
  if (selectedCategories.value.length > 0) {
    filtered = filtered.filter((show) => {
      const showCats = show.categories?.split(",").map((c) => c.trim()) || [];
      return selectedCategories.value.some((cat) => showCats.includes(cat));
    });
  }

  // Apply hosted filter
  if (selectedHosted.value.length > 0) {
    filtered = filtered.filter((show) =>
      selectedHosted.value.includes(show.hosted)
    );
  }

  return filtered;
});

const fetchAllContentPacks = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/content-packs`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    contentPacks.value = response.data;

    // Create mapping of show IDs to content packs
    const mapping = {};
    contentPacks.value.forEach((pack) => {
      pack.shows.forEach((showId) => {
        if (!mapping[showId]) {
          mapping[showId] = [];
        }
        mapping[showId].push({
          id: pack.id,
          name: pack.name,
        });
      });
    });
    showContentPacks.value = mapping;
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch content packs",
      life: 3000,
    });
  }
};

// Rest of the existing functions remain the same
const selectAllFiltered = () => {
  selectedShows.value = [...filteredShows.value];
};

const clearSelection = () => {
  selectedShows.value = [];
};

const fetchContentPack = async () => {
  if (!isEditing.value) return;

  try {
    loading.value = true;
    const response = await axios.get(
      `${API_BASE_URL}/content-packs/${packId.value}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    contentPackName.value = response.data.name;
    selectedShows.value = shows.value.filter((show) =>
      response.data.shows.includes(show.show_id)
    );
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Failed to fetch content pack details",
      life: 3000,
    });
    router.push("/admin/content-packs");
  } finally {
    loading.value = false;
  }
};

// Add these new methods
const addShow = (show) => {
  selectedShows.value.push(show);
};

const removeShow = (show) => {
  selectedShows.value = selectedShows.value.filter(
    (s) => s.show_id !== show.show_id
  );
};

// Add these new computed properties
const selectedShowsList = computed(() => {
  return selectedShows.value;
});

const availableShowsList = computed(() => {
  const selected = new Set(selectedShows.value.map((show) => show.show_id));
  return filteredShows.value.filter((show) => !selected.has(show.show_id));
});

// Update saveContentPack to use selectedShows directly
const saveContentPack = async () => {
  if (!contentPackName.value) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "Please enter a content pack name",
      life: 3000,
    });
    return;
  }

  try {
    loading.value = true;
    const payload = {
      name: contentPackName.value,
      shows: selectedShows.value.map((show) => show.show_id),
    };

    const url = isEditing.value
      ? `${API_BASE_URL}/content-packs/${packId.value}`
      : `${API_BASE_URL}/content-packs`;

    const method = isEditing.value ? "put" : "post";

    await axios[method](url, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });

    toast.add({
      severity: "success",
      summary: "Success",
      detail: `Content pack ${
        isEditing.value ? "updated" : "created"
      } successfully`,
      life: 3000,
    });

    router.push("/admin/content-packs");
  } catch (error) {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: `Failed to ${isEditing.value ? "update" : "create"} content pack`,
      life: 3000,
    });
  } finally {
    loading.value = false;
  }
};

const removeAllShows = () => {
  selectedShows.value = [];
};

const addAllFiltered = () => {
  availableShowsList.value.forEach((show) => {
    if (!selectedShows.value.find((s) => s.show_id === show.show_id)) {
      selectedShows.value.push(show);
    }
  });
};

onMounted(async () => {
  if (!shows.value || shows.value.length === 0) {
    await store.dispatch("loadShows", { token });
  }
  await fetchAllContentPacks();
  if (isEditing.value) {
    await fetchContentPack();
  }
});
</script>

<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
