<template>
  <Card class="my-3">
    <template #title>
      <div class="text-lg font-medium">Per Demand Partner Daily</div>
    </template>
    <template #content>
      <DataTable
        :value="tableData"
        :defaultSortOrder="-1"
        class="p-datatable-striped p-datatable-sm"
        :paginator="true"
        :rows="10"
        :totalRecords="totalRecords"
        lazy
        @page="fetchData"
        @sort="fetchData"
        :loading="tableLoader > 0"
      >
        <Column
          v-for="col in columns"
          :key="col.field"
          :field="col.field"
          :header="col.header"
          sortable
        ></Column>
        <template #loadingicon>
          <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
        </template>
        <template #paginatorstart>
          <Button
            type="button"
            icon="pi pi-refresh"
            @click="fetchData({ page: 0, rows })"
            text
          />
        </template>
        <template #paginatorend>
          <download-csv :data="csvData" class="download-csv">
            <Button type="button" icon="pi pi-download" text />
          </download-csv>
        </template>
      </DataTable>
    </template>
  </Card>
</template>

<script setup>
import Button from "primevue/button";
import Card from "primevue/card";
import { onBeforeMount } from "vue";
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const tableData = computed(
  () =>
    store.state.AdminNewDashboardStore.dataTables.demandPartnerDaily.tableData
);
const csvData = computed(
  () => store.state.AdminNewDashboardStore.dataTables.demandPartnerDaily.csvData
);
const totalRecords = computed(
  () =>
    store.state.AdminNewDashboardStore.dataTables.demandPartnerDaily
      .totalRecords
);
const tableLoader = computed(
  () => store.state.AdminNewDashboardStore.loader.admin_per_tagpartner_daily
);

const columns = [
  { header: "Date", field: "date_trunc" },
  { header: "Partner", field: "tag_partner" },
  { header: "Ad Impressions", field: "impressions" },
  { header: "Revenue", field: "revenue" },
  { header: "Requests", field: "requests" },
  { header: "Ad Play Rate", field: "fill_rate" },
];

const fetchData = ({
  page = 0,
  rows = 10,
  sortField,
  sortOrder,
  dataMode = "admin_per_tagpartner_daily",
}) => {
  store.dispatch("AdminNewDashboardStore/getTableData", {
    dataMode,
    page,
    rows,
    sortField,
    sortOrder,
  });
};

onBeforeMount(() => {
  fetchData({});
  fetchData({ dataMode: "admin_per_tagpartner_daily_all" });
});
</script>
