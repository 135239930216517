import { API_BASE_URL } from "@/consts";
import axios from "axios";

const token = () => localStorage.getItem("adminToken");

export const fetchMemberList = async () => {
  try {
    const { data } = await axios.get(API_BASE_URL + "/member-list/all", {
      headers: { Authorization: `Bearer ${token()}` },
    });
    if (data.success) {
      const { partners, groups, sellers } = data;
      return { partners, groups, sellers };
    }
    return null;
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const fetchTagGroups = async () => {
  try {
    const response = await axios.get(API_BASE_URL + "/tag-group", {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });
    if (response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching tag groups:", error);
    return null;
  }
};

export const fetchTags = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/tags`, {
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });

    if (response.data?.tags) {
      return response.data.tags;
    }
    return null;
  } catch (error) {
    console.error("Error fetching tags:", error);
    return null;
  }
};

export const fetchDailyPaces = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/admin-dashboard-widgets/daily-pace`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );

    if (response.data?.data) {
      return response.data.data;
    }

    return null;
  } catch (error) {
    console.error("Error fetching daily pace:", error);
    return null;
  }
};

export const fetchMRR = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/admin-dashboard-widgets/mrr`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    if (response.data?.data) {
      return response.data.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching MRR:", error);
    return null;
  }
};

export const fetchHourlyRevenue = async () => {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/admin-dashboard-widgets/hourly-revenue`,
      {
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    if (response.data?.data) {
      return response.data.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching hourly revenue:", error);
    return null;
  }
};

export const fetchTableData = async (params) => {
  try {
    const { data } = await axios.get(`${API_BASE_URL}/admin/dashboard`, {
      params,
      headers: { Authorization: `Bearer ${token()}` },
    });

    if (data.success) return data.data;
    return null;
  } catch (error) {
    console.error("Error fetching table datamode: ", params.dataMode, error);
    return null;
  }
};

export const fetchRevenueBetween = async (params) => {
  try {
    const { data } = await axios.get(
      `${API_BASE_URL}/admin-dashboard-widgets/revenue-between`,
      {
        params,
        headers: { Authorization: `Bearer ${token()}` },
      }
    );

    if (data.success) return data.data;
    return null;
  } catch (error) {
    console.error("Error fetching revenue between: ", error);
    return null;
  }
};
