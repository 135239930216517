import { adjustDate } from "@/helpers";
import {
  fetchDailyPaces,
  fetchHourlyRevenue,
  fetchMemberList,
  fetchMRR,
  fetchRevenueBetween,
  fetchTableData,
  fetchTagGroups,
  fetchTags,
} from "../AdminNewDashboardServices";

export const getMemberList = async ({ commit }) => {
  commit("setLoader", { widgetName: "memberList", isLoading: 1 });
  const memberListData = await fetchMemberList();
  if (memberListData) commit("setMemberList", memberListData);
  commit("setLoader", { widgetName: "memberList", isLoading: -1 });
};

export const getTagGroups = async ({ commit }) => {
  commit("setLoader", { widgetName: "tagGroups", isLoading: 1 });
  const tagGroupsData = await fetchTagGroups();
  if (tagGroupsData) commit("setTagGroups", tagGroupsData);
  commit("setLoader", { widgetName: "tagGroups", isLoading: -1 });
};

export const getTags = async ({ commit }) => {
  commit("setLoader", { widgetName: "tags", isLoading: 1 });
  const tagsData = await fetchTags();
  if (tagsData) commit("setTags", tagsData);
  commit("setLoader", { widgetName: "tags", isLoading: -1 });
};

export const applyFilter = ({ dispatch }) => {
  const dataModeArr = [
    "admin_daily",
    "admin_daily_all",
    "admin_per_group_daily",
    "admin_per_group_daily_all",
    "admin_per_domain_daily",
    "admin_per_domain_daily_all",
    "admin_per_tag_daily",
    "admin_per_tag_daily_all",
    "admin_per_seller_daily",
    "admin_per_seller_daily_all",
    "admin_per_partner_daily",
    "admin_per_partner_daily_all",
    "admin_per_partner_monthly",
    "admin_per_partner_monthly_all",
    "admin_per_tagpartner_monthly",
    "admin_per_tagpartner_monthly_all",
    "admin_per_tagpartner_daily",
    "admin_per_tagpartner_daily_all",
  ];
  dataModeArr.forEach((dataMode) => {
    dispatch("getTableData", { dataMode });
  });

  const pieDataModeArr = ["domain", "geo", "tag_partner"];

  pieDataModeArr.forEach((dataMode) => {
    dispatch("getRevenueBetween", { selectField: dataMode });
  });
};

export const getDailyPace = async ({ commit }) => {
  commit("setLoader", { widgetName: "dailyPace", isLoading: 1 });

  const dailyPaceData = await fetchDailyPaces();

  commit("setDailyPace", { dailyPaceData });
  commit("setLoader", { widgetName: "dailyPace", isLoading: -1 });
};

export const getMRR = async ({ commit }) => {
  commit("setLoader", { widgetName: "mrr", isLoading: 1 });
  const mrrData = await fetchMRR();
  if (mrrData) commit("setMRR", { mrrData });
  commit("setLoader", { widgetName: "mrr", isLoading: -1 });
};

export const getHourlyRevenue = async ({ commit }) => {
  commit("setLoader", { widgetName: "hourlyRevenue", isLoading: 1 });

  const hourlyRevenueData = await fetchHourlyRevenue();

  commit("setHourlyRevenue", { hourlyRevenueData });
  commit("setLoader", { widgetName: "hourlyRevenue", isLoading: -1 });
};

export const getTableData = async (
  { commit, state },
  { dataMode, page = 0, rows = 10, sortField, sortOrder }
) => {
  const startDate = adjustDate(state.dateRange?.[0])
    .toISOString()
    .split("T")[0];
  const endDate = adjustDate(state.dateRange?.[1]).toISOString().split("T")[0];

  const params = {
    startDate,
    endDate,
    dataMode,
    page,
    rows,
    sortField,
    sortOrder,
    memberFilter: JSON.stringify({
      ...state.memberFilter,
      tags: dataMode.includes("admin_per_tag_daily")
        ? state.memberFilter.tags
        : [],
      tag_partners: dataMode.includes("admin_per_tagpartner")
        ? state.memberFilter.tag_partners
        : [],
    }),
  };

  commit("setLoader", { widgetName: dataMode, isLoading: 1 });

  const response = await fetchTableData(params);

  commit(`set_${dataMode}`, { data: response });
  commit("setLoader", { widgetName: dataMode, isLoading: -1 });
};

export const getRevenueBetween = async ({ commit, state }, { selectField }) => {
  const selectFieldOptions = {
    domain: "url",
    geo: "geo",
    tag_partner: "tags.partner as tag_partner",
  };
  let selectFieldStr = selectFieldOptions[selectField];
  const startDate = adjustDate(state.dateRange?.[0])
    .toISOString()
    .split("T")[0];
  const endDate = adjustDate(state.dateRange?.[1]).toISOString().split("T")[0];
  const params = { startDate, endDate, selectField: selectFieldStr };

  commit("setLoader", {
    widgetName: `revenue_between_${selectField}`,
    isLoading: 1,
  });

  const response = await fetchRevenueBetween(params);

  commit(`set_revenue_between`, { data: response, selectField });
  commit("setLoader", {
    widgetName: `revenue_between_${selectField}`,
    isLoading: -1,
  });
};
