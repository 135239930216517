<template>
  <div class="mb-4 grid align-items-end" style="color: var(--text-color)">
    <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
      <label for="calendar">Select Date Range</label>
      <Calendar
        input-id="calendar"
        :model-value="dateRange"
        :showIcon="true"
        :number-of-months="2"
        :selectionMode="'range'"
        class="w-full"
        style="color: var(--text-color) !important"
        @update:modelValue="setDateRange"
      >
      </Calendar>
    </div>
    <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
      <label for="partners-list">Select Partners</label>
      <MultiSelect
        input-id="partners-list"
        display="chip"
        :options="memberList.partners"
        :model-value="memberFilter.partners"
        @change="(e) => setMemberFilter({ partners: e.value })"
        option-label="name"
        option-value="id"
        placeholder="Select Partners"
        class="w-full"
        :loading="store.state.AdminNewDashboardStore.loader['memberList'] > 0"
      />
    </div>
    <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
      <label for="groups-list">Select Groups</label>
      <MultiSelect
        input-id="groups-list"
        filter
        display="chip"
        :options="memberList.groups"
        :model-value="memberFilter.groups"
        @change="(e) => setMemberFilter({ groups: e.value })"
        option-label="name"
        option-value="id"
        placeholder="Select Groups"
        class="w-full"
        :loading="store.state.AdminNewDashboardStore.loader['memberList'] > 0"
      />
    </div>
    <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
      <label for="sellers-list">Select Sellers</label>
      <MultiSelect
        input-id="sellers-list"
        filter
        display="chip"
        :options="memberList.sellers"
        :model-value="memberFilter.sellers"
        @change="(e) => setMemberFilter({ sellers: e.value })"
        :optionLabel="(option) => `[${option.id}] ${option.name}`"
        option-value="id"
        placeholder="Select Sellers"
        class="w-full"
        :loading="store.state.AdminNewDashboardStore.loader['memberList'] > 0"
      />
    </div>
    <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
      <label for="tags-list">Select Tags</label>
      <MultiSelect
        input-id="tags-list"
        filter
        display="chip"
        :options="tags"
        :model-value="memberFilter.tags"
        @change="(e) => setMemberFilter({ tags: e.value })"
        optionLabel="name"
        option-value="id"
        placeholder="Select Tags"
        class="w-full"
        :loading="store.state.AdminNewDashboardStore.loader['tags'] > 0"
      />
    </div>
    <div class="col-12 sm:col-6 lg:col-3 flex flex-column gap-1">
      <label for="taggroups-list">Select Tag Partners</label>
      <MultiSelect
        input-id="taggroups-list"
        filter
        display="chip"
        :options="tagGroups"
        :model-value="memberFilter.tag_partners"
        @change="(e) => setMemberFilter({ tag_partners: e.value })"
        optionLabel="name"
        option-value="name"
        placeholder="Select Tag Partners"
        class="w-full"
        :loading="store.state.AdminNewDashboardStore.loader['tagGroups'] > 0"
      />
    </div>
    <div class="col-12 sm:col-6 lg:col-3">
      <Button @click="applyFilter" class="justify-content-center w-full"
        >Fetch Data</Button
      >
    </div>
  </div>
</template>

<script setup>
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import MultiSelect from "primevue/multiselect";
import { computed } from "vue";
import { onBeforeMount, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const emit = defineEmits(["apply-filter"]);

const memberList = computed(
  () => store.state.AdminNewDashboardStore.memberList
);
const tagGroups = computed(() => store.state.AdminNewDashboardStore.tagGroups);
const tags = computed(() => store.state.AdminNewDashboardStore.tags);
const memberFilter = computed(
  () => store.state.AdminNewDashboardStore.memberFilter
);
const dateRange = computed(() => store.state.AdminNewDashboardStore.dateRange);

const setDateRange = (value) => {
  store.commit("AdminNewDashboardStore/setDateRange", value);
};

const setMemberFilter = (filterValue) => {
  console.log("FILTER:??? ", filterValue);
  store.commit("AdminNewDashboardStore/setMemberFilter", filterValue);
};

const applyFilter = () => {
  emit("apply-filter");
};

onBeforeMount(() => {
  let today = new Date();
  today.setHours(0, 0, 0, 0);
  let thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 7);
  thirtyDaysAgo.setHours(0, 0, 0, 0);

  setDateRange([thirtyDaysAgo, today]);

  store.dispatch("AdminNewDashboardStore/getMemberList");
  store.dispatch("AdminNewDashboardStore/getTagGroups");
  store.dispatch("AdminNewDashboardStore/getTags");
});
</script>
